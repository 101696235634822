// in src/MyAppBar.js
import * as React from 'react';
import { AppBar } from 'react-admin';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const appInfo = require('../appInfo.json');

const useStyles = makeStyles({
    
    title: {
        flex: 0.99,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 0.01,
    },
});

const MyAppBar = props => {
    const classes = useStyles();

    return (
        <AppBar sx={{ displayPrint: 'none' }} {...props} >
            <a href="/">
                <img src="KirjainK.png" alt="Kirjain K logo" height={30}/>
            </a>
            <span className={classes.spacer} />
            <Typography
                variant="h6"
                color="inherit"
                background-color="#043c54"
                className={classes.title}
                id="react-admin-title"
            />
            <Typography
                variant="h6"
                color="inherit"
                background-color="#043c54"
                id="react-admin-version"
            >v.{appInfo.version}</Typography>            
            <span className={classes.spacer} />
                
            </AppBar>
    );
};

export default MyAppBar;