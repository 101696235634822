import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Money from '@mui/icons-material/Money';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PaperbaseTheme from '../themes/PaperbaseTheme';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { getRole } from '../helpers/getRole';
const vendorRoles = ['vendor', 'kirjapinoVendor', 'antikkaVendor', 'admin'];

const MyMenu = (props) => {
    const [role, setRole] = React.useState(null);

    React.useEffect(() => {
        const fetchRole = async () => {
            const userRole = await getRole();
            setRole(userRole);
        };

        fetchRole();
    }, []);    
    return (
    <Menu theme={PaperbaseTheme}  {...props} >
                {(vendorRoles.includes(role)) &&
                <MenuItemLink to="/orders" primaryText="Tilaukset" leftIcon={<Money />}/>
                }
                {(role === 'vendor' || role === 'admin') &&    
                <MenuItemLink to="/products" primaryText="Kirjat" leftIcon={<MenuBookIcon />}/>
            }
                {(role === 'antikkaVendor') &&    
                <MenuItemLink to="/products" primaryText="Kirjat" leftIcon={<MenuBookIcon />}/>
            }
                
                <MenuItemLink to="/batch" primaryText="Eräajo" leftIcon={<AddToQueueIcon />}/>
                {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&    
                <MenuItemLink to="/reports" primaryText="Eräajon Raportit" leftIcon={<SummarizeIcon />}/>
            }
            {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&    
                <MenuItemLink to="/sales" primaryText="Myynnit" leftIcon={<MonetizationOnIcon />}/>
            }
    </Menu>
    
    );
}

export default MyMenu;
