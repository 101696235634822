import React from "react";
import PropTypes from 'prop-types';

import {
    ArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import Grid from '@mui/material/Grid';

import { getEditions } from "../helpers/getEditions";
import { getYears } from "../helpers/getYears";
import categories from "../data/categories.json";
import series from "../data/series.json";
import languages from "../data/languages.json"
import binding from "../data/binding.json";
import condition from "../data/condition.json";

const years = getYears();


const isRequired = required("Kenttä on pakollinen.")
const ProductTitle = ({ record }) => {
    return <span>{record?.attributes?.find(attribute => attribute.id === 8) ? `${record.attributes.find(attribute => attribute.id === 8).options} / ${record.name}` : ''}</span>;
};
ProductTitle.propTypes = {
    record: PropTypes.object,
  };
const editions = getEditions();

export const ProductEditView = props => (
    <Edit title={<ProductTitle />} {...props} >
        <SimpleForm
            variant="outlined"
        >
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                <ImageInput 
                    fullWidth 
                    variant="outlined" 
                    source="image"
                    label="Kuva"
                    accept="image/*"
                    placeholder={<p>Lisää kuva tähän</p>}>
                            <ImageField 
                            sx={{alignContent: 'center'}}
                            variant="outlined" 
                            source="src" 
                            title="title" />
                        </ImageInput>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <RadioButtonGroupInput variant="outlined" label="Varaston tila" source="stock_status" choices={[
                        { id: 'instock', name: 'Myynnissä' },
                        { id: 'outofstock', name: 'Ei myynnissä' },
                    ]} />
                    
                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput variant="outlined" validate={isRequired} label="Nimi" source="name" />
                    <TextInput variant="outlined" label="Tekijä" source="author" />


                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <NumberInput 
                    variant="outlined" 
                    validate={isRequired}  
                    label="Hinta (€)"  
                    options={{ style: 'currency', currency: 'EUR' }}
                    source="price" />
                    <TextInput 
                    variant="outlined" 
                    label="Sivumäärä" 
                    source="pages" 
                    // validate={regex(/(^$)|\d+(?:,\d+)*/g, 'Vain numerot ja pilkut hyväksytään.')}
                    />
                </Grid>
            </Grid>
            
            <Grid Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput 
                    sx={{height: 100}} 
                    variant="outlined" label="Kustantaja" source="publisher" />

                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <NumberInput 
                    sx={{height: 100}} 
                    validate={isRequired}  variant="outlined" min="0" initialValue="1" label="Määrä" source="stock_quantity" />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <ArrayInput fullWidth sx={{ marginTop: -1}} label={false}  source="categories">
                        <SimpleFormIterator 
                        fullWidth
                        disableReordering  disableAdd  >
                            <SelectInput
                                fullWidth 
                                validate={isRequired}  
                                label="Osasto"
                                variant="outlined"
                                optionValue="name"
                                source="name"
                                choices={categories}
                                />
                        </SimpleFormIterator>
                    </ArrayInput>

                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput variant="outlined" multiline fullWidth  minRows={2} label="Kuvaus" source="description" />

                </Grid>
            </Grid>
            <Grid Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput 
                    sx={{marginTop: 2}}
                    variant="outlined" label="Sarja" optionValue="name" source="series" choices={series}/>


                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput 
                    sx={{marginTop: 2}} 
                    variant="outlined" label="Painopaikka" source="print_place" />


                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput
                    sx={{marginTop: 2}}
                    translateChoice={false}
                    variant="outlined" 
                    label="Painos" 
                    source="edition" 
                    choices={editions}
                    />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <AutocompleteArrayInput
                sx={{marginTop: 2}} 
                translateChoice={false}
                label="Painovuosi" 
                variant="outlined" 
                source="year" 
                choices={years}
                />
                    
                </Grid>
            </Grid>
            <Grid Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput
                    sx={{marginTop: 2}}  
                    variant="outlined" 
                    optionValue="name" 
                    optionText="name"
                    label="Kieli" 
                    source="language" 
                    choices={languages}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput 
                    sx={{marginTop: 2}} 
                    variant="outlined" optionValue="name" label="Kuntoluokka" source="condition" choices={condition}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput
                    sx={{marginTop: 2}}  
                    variant="outlined" 
                    optionValue="name" 
                    label="Sidosasu" 
                    source="layout"
                    choices={binding}
                    />
                </Grid>
            </Grid>
            <Grid Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                <TextInput variant="outlined" label="Varasto" source="storage" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="Hylly" source="shelf" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="ISBN" source="isbn" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <BooleanInput
                label="Lisätäänkö Antikvaariin?"
                
                source="targets" />
                </Grid>
            </Grid>               
        </SimpleForm>
    </Edit>
);