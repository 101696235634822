import { ArrayField, Datagrid, List, NumberField, TextField } from 'react-admin';

export const SalesReportList = () => (
    <List 
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    sx={{ 
        padding: 2, 
        spacing: 2, 
        marginTop: 2, 
        marginBottom: 2, 
        marginLeft: 2, 
        marginRight: 2, 
        border: "0.2px", 
        borderRadius: "10px", 
        boxShadow: "0px 0px 1px 0px #000000"
    }}>
        <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField label="Myyntikuukausi" source="id" />
        <NumberField label="Kokonaismyynti" source="total_sales" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
        <NumberField label="Myynti ilman postikuluja" source="net_sales" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
        <NumberField label="Postikulut" source="shipping_total" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
        <ArrayField label="Sivustokohtainen myynti" source="sites">
                    <Datagrid optimized
                    bulkActionButtons={false}
                    >
                        <TextField label="Sivusto" source="id" />
                        <NumberField label="Kokonaismyynti" source="total_sales" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
        <NumberField label="Myynti ilman postikuluja" source="net_sales" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
        <NumberField label="Postikulut" source="shipping_total" locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
                    </Datagrid>
                </ArrayField>
        </Datagrid>
    </List>
);