// LoginPage.js
import React from "react";
import { Login, LoginForm } from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import ForgotPasswordButton from './CustomForgotPassword'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google as auth provider.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

const SignInScreen = () => <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>;


const CustomLoginForm = props => (
  <div>
    <LoginForm {...props} />
    <SignInScreen />
    <ForgotPasswordButton {...props} />
  </div>
);

const CustomLoginPage = props => (
  <Login
  backgroundImage="https://kirjapino.fi/wp-content/uploads/2022/10/kirjahylly-1-1.jpg"
  {...props}>
    <CustomLoginForm {...props}/>
  </Login>
);

export default CustomLoginPage;