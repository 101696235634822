import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {initializeApp} from "firebase/app";
import {firebaseConfig as config} from "../FIREBASE_CONFIG";
const app = initializeApp(config);
const storage = getStorage(app);


export async function storageUploader(file, imageName) {
// Create the file metadata
// @type {any}
const metadata = {
  contentType: 'image/*',
};
const folder = 'images/' + Date.now() + '/'
// Upload file and metadata to the object 'images/mountains.jpg'
const storageRef = ref(storage, folder + imageName);
const uploadedImage = await uploadBytesResumable(storageRef, file.rawFile, metadata).then((snapshot) => {
  console.log(snapshot.metadata)
  return snapshot.metadata;
});

return Promise.resolve(uploadedImage.fullPath);
}