function calculateTotalSales(order) {
    const lineItems = order.line_items || [];
    let totalSales = 0;
    for (let lineItem of lineItems) {
        totalSales += parseFloat(lineItem.total);
    }
    return totalSales;
}

function calculateSippingTotals(order) {
    const lineItems = order.shipping_lines || [];
    let totalSales = 0;
    for (let lineItem of lineItems) {
        totalSales += parseFloat(lineItem.total);
    }
    return totalSales;
}


/**
 * 
 * @param {Array} snapshot 
 * @return {Object} report
 */
export function createSalesReport(snapshot) {
    const monthlyReport = {};
    const allowedStatuses = ['completed', 'processing', 'pending'];
    snapshot.forEach(orderSnapshot => {
        const order = orderSnapshot.val();
        const dateCreated = new Date(order.date_created);
        const month = `${dateCreated.getFullYear()}-${(dateCreated.getMonth() + 1).toString().padStart(2, '0')}`;
        const source = order.source;
        const totalSales = calculateTotalSales(order);
        const shippingTotal = calculateSippingTotals(order);

        // If the month doesn't exist yet, create an entry
        if (!monthlyReport[month]) {
            monthlyReport[month] = {
                id: month,
                total_sales: 0,
                shipping_total: 0,
                net_sales: 0,
                sites: {}  // Hold site-specific data
            };
        }

        // Aggregate overall month totals
        if (allowedStatuses.includes(order.status)) {
        monthlyReport[month].total_sales += totalSales;
        monthlyReport[month].shipping_total += shippingTotal;
        monthlyReport[month].net_sales += totalSales - shippingTotal;
        }
        // If the site/source doesn't exist for the month, create an entry
        if (!monthlyReport[month].sites[source]) {
            monthlyReport[month].sites[source] = {
                id: source,
                total_sales: 0,
                shipping_total: 0,
                net_sales: 0
            };
        }

        // Aggregate site-specific totals
        if (allowedStatuses.includes(order.status)) {
        monthlyReport[month].sites[source].total_sales += totalSales;
        monthlyReport[month].sites[source].shipping_total += shippingTotal;
        monthlyReport[month].sites[source].net_sales += totalSales - shippingTotal;
        }
    });

    // Convert site objects to arrays and filter out invalid months
    return Object.values(monthlyReport)
        .map(monthReport => ({
            ...monthReport,
            sites: Object.values(monthReport.sites)
        }))
        .filter(monthReport => monthReport.id !== "NaN-NaN");
}