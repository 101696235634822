export function getEditions(){
    const editions = [];
  
    for (let i = 1; i <= 100; i++) {
      editions.push({
        id: i,
        name: `${i}.p`,
      });
    }
  
    return editions;
  };
