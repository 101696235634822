import {createTheme} from "@mui/material/styles";

let PaperbaseTheme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#b0402e',
            dark: '#043c54',
        },
        secondary: {main: '#043c54'},
        error: {main: '#d32f2f'},
        warning: {main: '#ffb74d'},
        success: {main: '#388e3c'},
        info: {main: '#0288d1'},
        test: {main: '#ff0000'},
        none : {main: '#ffffff'},
    },
    sidebar:{
        width: 150,
        border: '1px dotted #043c54',
        margin: '0 12px',
        minWidth: 1,
        padding: 1,
    },
    typography: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
        h6: {
            fontWeight: 500,
            color: 'inherit',
            fontSize: '1rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: 'inherit',
            fontWeight: 500
        },
        h4: {
            fontSize: '1.1rem',
            color: 'inherit',
            fontWeight: 550
        },
        h3: {
            fontSize: '1.25rem',
            color: 'inherit',
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: 'inherit',
            fontWeight: 700
        },
        h1: {
            fontSize: '1.875rem',
            color: 'inherit',
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 500,
            color: 'secondary'
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 450,
            color: 'secondary'
        },
        caption: {
            fontSize: '0.9rem',
            color: 'secondary',
            fontWeight: 400
        },
        body1: {
            fontSize: '1.1rem',
            fontWeight: 500,
            lineHeight: '1.5em'
        },
        body2: {
            fontSize: '1rem',
            letterSpacing: '0em',
            fontWeight: 450,
            lineHeight: '1.5em',
            color: 'primary'
        },
        button: {
            textTransform: 'capitalize'
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 128,
        },
    },
});

PaperbaseTheme = {
    ...PaperbaseTheme,
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#043c54',
                },
                root:{
                    textTransform: 'none',
                    margin: '0 16px',
                    border: '1px',
                    minWidth: 12,
                    padding: 1,
                    [PaperbaseTheme.breakpoints.up('md')]: {
                        padding: 2,
                        minWidth: 2,
                    },
                }
            },
        },
        MuiGrid: {
            defaultProps : {
                spacing: 1,
                marginBottom: 0.5,
                marginTop: 0.2,
                alignContent: 'flex-start',
                alignItems: 'flex-start'
            },
            styleOverrides: {
                root:{
                    textTransform: 'none',
                }
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                padding: 1.5,
                fullWidth: true,
                height: '64px',
            },
        },
        MuiInputBase: {
            defaultProps: {
                notched: false,
                variant: 'outlined',
                padding: 1.5,
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    backgroundColor: PaperbaseTheme.palette.common.white,
                    height: "3em",
                    
            '&.MuiInputBase-multiline': {
                minHeight: '6em', // minRows=2 as CSS (assuming 2 rows is approximately 4em)
            },
            },
            },
        },
        MuiTablePagination: {
            defaultProps: {
                rowsPerPageOptions: [5, 10, 25, 50, 100],
            },
            styleOverrides: {
                root: {
                    fontSize: 12,
                },
                input: {
                    width: '10%',
                },

            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: PaperbaseTheme.spacing(2),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: PaperbaseTheme.palette.common.white,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 1,
                    [PaperbaseTheme.breakpoints.up('md')]: {
                        padding: 1,
                        minWidth: 1,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: PaperbaseTheme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#043c54',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 24,
                    fontWeight: PaperbaseTheme.typography.fontWeightBold,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: PaperbaseTheme.spacing(2),
                    '& svg': {
                        fontSize: 32,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
    },
    overrides: {
        MuiCssBaseline: {
            
            '@global': {
                // ... other global styles ...
                '@media print': {
                    '& .RaSidebar*':{
                        width: 0,
                        border: '0px dotted #043c54',
                        margin: '0 0px',
                        minWidth: 1,
                        padding: 1,
                    },
                },
            },
        },
    },
};

export default PaperbaseTheme;