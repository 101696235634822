import {  FirebaseAuthProvider } from "react-admin-firebase";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';
const authProvider = FirebaseAuthProvider(config);


const vendors = require('../config/vendors.json');

export async function getVendor() {
    const user  =  await authProvider.checkAuth();
    const vendorUid = user.uid;
    const userVendors = vendors[vendorUid].name;
    console.log("Vendors: " + userVendors); 
    return userVendors;
}