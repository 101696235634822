import {  FirebaseAuthProvider } from "react-admin-firebase";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';
const authProvider = FirebaseAuthProvider(config);


const vendors = require('../config/vendors.json');

export async function getRole() {
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    const role = vendors[vendorUid].role;
    const vendorName = vendors[vendorUid].name;
    console.log('vendorName', vendorName);
    console.log('role', role);
    return role;
}