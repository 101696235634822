import React from "react";
import PropTypes from 'prop-types';

import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput,
    DateInput,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { getEditions } from "../helpers/getEditions";
import { getYears } from "../helpers/getYears";
import { getVendor } from "../helpers/getVendor";
import categories from "../data/categories.json";
import series from "../data/series.json";
import languages from "../data/languages.json"
import binding from "../data/binding.json";
import condition from "../data/condition.json";

const years = getYears();

const ImageButton = () => (
    <Button variant="contained">Lisää kuva..</Button>
);

const isRequired = required("Kenttä on pakollinen.")
const ProductTitle = ({ record }) => {
    return <span>{record?.attributes?.find(attribute => attribute.id === 8) ? `${record.attributes.find(attribute => attribute.id === 8).options} / ${record.name}` : ''}</span>;
};
ProductTitle.propTypes = {
    record: PropTypes.object,
  };
const editions = getEditions();

export const BatchProductEditView = props => {
    const [vendors, setVendors] = React.useState([]);

    React.useEffect(() => {
        const fetchVendor = async () => {
            const userVendors = await getVendor();
            for (let i = 0; i < userVendors.length; i++) {
                const vendorName = userVendors[i]?.name || userVendors[i] ;
                userVendors[i] = { id: i, name: vendorName };
            }
            setVendors(userVendors);
        };

        fetchVendor();
    }, []);
    return (
    <Edit title={<ProductTitle />} {...props} >
        <SimpleForm
            variant="outlined"
        >
            <Grid container alignItems="flex-start" spacing={1} >
                
                <Grid item sm={12} xs={12} md={2}>
                        <DateInput 
                        fullWidth label="Julkaisupäivä" 
                        source="release_date" 
                        />
                        <AutocompleteInput label="Myyjä" source="batchVendor" optionValue="name" choices={vendors} />
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>
                        <ImageInput 
                            fullWidth 
                            variant="outlined" 
                            source="image"
                            label="Kuva"
                            accept="image/*"
                            placeholder={<ImageButton />}>
                            <ImageField variant="outlined" source="src" title="title" />
                        </ImageInput>
                    </Grid>
                
                <Grid item sm={6} xs={6} md={2}>
                <RadioButtonGroupInput variant="outlined" label="Varaston tila" source="stock_status" choices={[
                        { id: 'instock', name: 'Myynnissä' },
                        { id: 'outofstock', name: 'Ei myynnissä' },
                    ]} />
                    
                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput variant="outlined" validate={isRequired} label="Nimi" source="name" />
                    <TextInput variant="outlined" label="Tekijä" source="author" />


                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <NumberInput 
                    variant="outlined" 
                    validate={isRequired}  
                    label="Hinta (€)"  
                    options={{ style: 'currency', currency: 'EUR' }}
                    source="price" />
                    <TextInput 
                    variant="outlined" 
                    label="Sivumäärä" 
                    source="pages" 
                    // validate={regex(/(^$)|\d+(?:,\d+)*/g, 'Vain numerot ja pilkut hyväksytään.')}
                    />
                </Grid>
            </Grid>
            
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput 
                    variant="outlined" label="Kustantaja" source="publisher" />

                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <NumberInput 
                    validate={isRequired}  variant="outlined" min="0" initialValue="1" label="Määrä" source="stock_quantity" />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <ArrayInput fullWidth label={false} sx={{marginTop: -1}} source="categories">
                        <SimpleFormIterator 
                        fullWidth
                        disableReordering  disableAdd  >
                            <SelectInput
                                fullWidth 
                                validate={isRequired}  
                                label="Osasto"
                                variant="outlined"
                                optionValue="name"
                                source="name"
                                choices={categories}
                                />
                        </SimpleFormIterator>
                    </ArrayInput>

                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput variant="outlined" multiline fullWidth  minRows={2} label="Kuvaus" source="description" />

                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput 
                    sx={{marginTop: -0.5}}
                    variant="outlined" label="Sarja" optionValue="name" source="series" choices={series}/>


                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput 
                     
                    variant="outlined" label="Painopaikka" source="print_place" />


                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput
                    
                    translateChoice={false}
                    variant="outlined" 
                    label="Painos" 
                    source="edition" 
                    choices={editions}
                    />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <AutocompleteArrayInput
                 
                translateChoice={false}
                label="Painovuosi" 
                variant="outlined" 
                source="year" 
                choices={years}
                />
                    
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput
                     sx={{marginTop: 1}} 
                    variant="outlined" 
                    optionValue="name" 
                    optionText="name"
                    label="Kieli" 
                    source="language" 
                    choices={languages}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput 
                     
                    variant="outlined" optionValue="name" label="Kuntoluokka" source="condition" choices={condition}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <SelectInput
                      
                    variant="outlined" 
                    optionValue="name" 
                    label="Sidosasu" 
                    source="layout"
                    choices={binding}
                    />
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                <TextInput variant="outlined" label="Varasto" source="storage" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="Hylly" source="shelf" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="ISBN" source="isbn" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <BooleanInput
                label="Lisätäänkö Antikvaariin?"
                //defaultChecked={true}
                source="targets" />
                </Grid>
            </Grid>               
        </SimpleForm>
    </Edit>
);}