export function getYears(){
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1455; i--) {
      years.push({
        id: i,
        name: i,
      });
    }
    return years;
  };
