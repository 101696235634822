import React from "react";
import productTemplate from "../data/productTemplate.json";
import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    TextInput,
} from "react-admin";

import Grid from '@mui/material/Grid';


import { getEditions } from "../helpers/getEditions";
import { getYears } from "../helpers/getYears";
import categories from "../data/categories.json";
import series from "../data/series.json";
import languages from "../data/languages.json"
import binding from "../data/binding.json";
import condition from "../data/condition.json";

const postDefaultValue = () => (productTemplate);
const isRequired = required("Kenttä on pakollinen.")

const editions = getEditions();
const years = getYears();



export const AntikkaProductCreateView = (props) => (
    <Create 
    redirect={false}
    title="Lisää kirja" 
    {...props} >

        <SimpleForm initialvalues={postDefaultValue} variant="outlined">
                <Grid container alignItems="flex-start" spacing={1} >
                    <Grid item sm={12} xs={12} md={4}>
                        <ImageInput
                            fullWidth
                            variant="outlined"
                            source="image"
                            label="Kuva"
                            accept="image/*"
                            placeholder={<p>Lisää kuva tähän</p>}>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>
                        <RadioButtonGroupInput 
                        label="Varaston tila" 
                        source="stock_status" 
                        choices={[
                                    { id: 'instock', name: 'Myynnissä' },
                                    { id: 'outofstock', name: 'Ei myynnissä' },
                                ]} 
                        />
                        
                    </Grid>
                    <Grid item sm={12} xs={12} md={4}>
                        <TextInput fullWidth validate={isRequired} allowEmpty={false} label="Nimi" source="name" />
                        <TextInput fullWidth label="Tekijä" source="author" />
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>                    
                     <AutocompleteArrayInput 
                        translateChoice={false} 
                        emptyText="" 
                        emptyValue={0} 
                        label="Painovuosi" 
                        allowEmpty={true} 
                        type="number" 
                        source="year" 
                        choices={years}
                        aria-sort="descending"
                        />
                     <AutocompleteArrayInput 
                        allowEmpty={true}
                        label="Painos"
                        source="edition"
                        choices={editions}
                        />
                </Grid>
                </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
               
                <Grid item sm={12} xs={12} md={2}>
                    <TextInput 
                        label="Kustantaja" 
                        source="publisher"
                        sx={{height: 100}} 
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={1} >
                <TextInput
                    label="Sivumäärä"  
                    source="pages"
                    allowEmpty={true}
                    //validate={regex(/(^$)|\d+(?:,\d+)*/g, 'Vain numerot ja pilkut hyväksytään.')}
                     />
                </Grid>
                <Grid item sm={12} xs={12} md={1}>
                <NumberInput
                    validate={isRequired} 
                    allowEmpty={false} 
                    min="1" 
                    label="Hinta (€)"
                    options={{ style: 'currency', currency: 'EUR' }}
                    source="price" />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <NumberInput
                    sx={{height: 100}}
                    validate={isRequired}   
                    allowEmpty={false} 
                    min="0" 
                    initialValue="1"  
                    label="Määrä" 
                    source="stock_quantity" 
                />
                </Grid>
                <Grid item sm={12} xs={12} md={3}> 
                         <ArrayInput sx={{marginTop: -4}} source="category">
                           <AutocompleteInput
                           fullWidth
                           validate={isRequired}
                                label="Osasto"
                                allowEmpty="false"
                                optionValue="name"
                                initialValue="Kaunokirjallisuus"
                                source="name"
                                choices={categories}
                            />
                        </ArrayInput>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <AutocompleteArrayInput optionValue="name" initialValue="suomi" label="Kieli" source="language" choices={languages}/>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
            <Grid item sm={12} xs={12} md={3}>
                    <TextInput fullWidth multiline minRows={2} label="Kuvaus" source="description" />
                </Grid>

                <Grid item sm={12} xs={12} md={3}>
                <AutocompleteInput label="Sarja" optionValue="name" source="series" choices={series}/>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <AutocompleteInput optionValue="name" initialValue="K4 (erinomainen)" label="Kuntoluokka" source="condition" choices={condition}/>
                    </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <AutocompleteInput optionValue="name" label="Sidosasu" initialValue="Sidottu, ei kansipaperia" source="layout" choices={binding}/>
                    </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <TextInput label="Painopaikka" source="print_place" />
                    </Grid>             
               
                
            </Grid>
            <Grid container alignItems="flex-start" spacing={1}>
    
                    <Grid item sm={12} xs={12} md={3}>
                    <TextInput label="Varastopaikka" source="storage" />
                    </Grid>
                    <Grid item sm={12} xs={12} md={3}>
                    <TextInput label="Hylly" source="shelf" />
                </Grid>
                    <Grid item sm={12} xs={12} md={3}>
                <TextInput label="ISBN" source="isbn" />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <BooleanInput
                label="Lisätäänkö Antikvaariin?"
                defaultValue={true}
                defaultChecked={true}
                source="targets" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);