
// in src/App.js
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import fbDataProvider from './api/fb-dataProvider';
import { OrderDetailView } from './views/OrderDetailView';
import { ProductCreateView } from './views/ProductCreateView';
import {OrderPrintView} from './views/OrderPrintView';
import ProductListView from "./views/ProductListView";
import { BatchListView } from "./views/BatchListView";
import { BatchProductCreateView } from "./views/BatchProductCreateView";
import { BatchProductEditView } from "./views/BatchProductEditView";
import { OrderListView } from "./views/OrderListView";
import { Book } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import finnishMessages from 'ra-language-finnish';
import {
  FirebaseAuthProvider
} from "react-admin-firebase";
import MyLayout from './components/MyLayout';
import { getRole } from './helpers/getRole';
import CustomLoginPage from './pages/LoginPage';

import { firebaseConfig as config } from './FIREBASE_CONFIG';
import {ProductEditView} from "./views/ProductEditView";
import PaperbaseTheme from "./themes/PaperbaseTheme";
import { AntikkaProductCreateView } from "./views/AntikkaProductCreateView";
import { AntikkaProductEditView } from "./views/AntikkaProductEditView";
import { AntikkaBatchProductCreateView } from "./views/AntikkaBatchProductCreateView";
import { AntikkaBatchProductEditView } from "./views/AntikkaBatchProductEditView";
import { BatchReportList } from "./views/BatchReportList";
import { SalesReportList } from "./views/SalesReportList";

const options = {
  logging: true,
  rootRef: 'products/kirjapino',
}

const i18nProvider = polyglotI18nProvider(() => finnishMessages, 'fi');
const dataProvider = fbDataProvider;
const authProvider = FirebaseAuthProvider(config, options);

const vendorRoles = ['vendor', 'kirjapinoVendor', 'antikkaVendor', 'admin'];
const antikkaRoles = ['antikkaVendor', 'antikkaEditor'];

const App = () => {
  // Fetch the user role
  const [role, setRole] = React.useState(null);

    React.useEffect(() => {
      try {
        const fetchRole = async () => {
            const userRole = await getRole();
            setRole(userRole);
        };
      
        fetchRole();
      } catch (error) {
        console.log(error);
    }
    }, []);
  return (
          <Admin 
          theme={PaperbaseTheme}
          layout={MyLayout}
          loginPage={CustomLoginPage} 
          title="admin.kirjain.xyz"
          i18nProvider={i18nProvider} 
          dataProvider={dataProvider} 
          authProvider={authProvider}
          //queryClient={queryClient}
          >
          {(vendorRoles.includes(role)) &&
            <Resource 
           options={{ label: 'Tilaukset'}} 
           name="orders"
           list={OrderListView}
           edit={OrderDetailView}
           show={OrderPrintView}

           /> 
          }
          {(role === 'vendor' || role === 'admin') &&
            <Resource 
              options={{ label: 'Kirjat' }} 
              name="products"
              list={ProductListView}
              edit={ProductEditView}
              create={ProductCreateView}
              icon={Book}
            />
            }
            {(role === 'antikkaVendor') &&
            <Resource 
              options={{ label: 'Antikka Kirjat' }} 
              name="products"
              list={ProductListView}
              edit={AntikkaProductEditView}
              create={AntikkaProductCreateView}
              icon={Book}
            />
          }
          {(!antikkaRoles.includes(role)) &&
           <Resource 
           options={{ label: 'Eräajo' }} 
           name="batch"
           list={BatchListView}
           edit={BatchProductEditView}
           create={BatchProductCreateView}
           icon={Book}
           />}
           {(antikkaRoles.includes(role)) &&
           <Resource 
           options={{ label: 'Eräajo' }} 
           name="batch"
           list={BatchListView}
           edit={AntikkaBatchProductEditView}
           create={AntikkaBatchProductCreateView}
           icon={Book}
           />}
           {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&
            <Resource 
              options={{ label: 'Eräajon Raportit' }} 
              name="reports"
              list={BatchReportList}
              icon={SummarizeIcon}
            />
            }
            {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&
            <Resource 
              options={{ label: 'Myynnit' }} 
              name="sales"
              list={SalesReportList}
              icon={MonetizationOnIcon}
            />
            }

          </Admin>)
          };

export default App;